<template lang="pug">
b-navbar(variant='transparent', type='light', style='background: #f6f8f9 !important; box-shadow: 1px 0 5px 1px rgba(0, 0, 0, 0.1) inset')
  b-container.flex-center
    b-navbar-nav.flex-column.flex-md-row
      b-nav-item(disabled) Matelco S.A. de C.V. ®
      b-nav-item(@click='privacy') Aviso de privacidad
      b-nav-item(@click='contact') Contáctanos
      b-nav-item.d-md-none
        b-img.mr-3(src='@/assets/images/icons/fb.png', height='25')
        b-img(src='@/assets/images/icons/twitter.png', height='25')
    //- b-navbar-nav.d-none.d-md-flex.ml-md-auto
    //-   b-nav-item
    //-     b-img(src='@/assets/images/icons/fb.png', height='25')
    //-   b-nav-item
    //-     b-img(src='@/assets/images/icons/twitter.png', height='25')
</template>

<script>
export default {
  methods: {
    privacy() {
      if (this.$route.path !== '/aviso-de-privacidad') this.$router.push({ name: 'Privacy' })
    },
    contact() {
      if (this.$route.path !== '/contacto') this.$router.push({ name: 'Contact' })
    },
  },
}
</script>

<style lang="scss" scoped>
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>