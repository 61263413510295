<template lang="pug">
.navbar-container
  b-navbar(variant='primary', type='dark', toggleable='lg')
    b-container
      b-navbar-toggle.ml-auto(target='contact-bar', style='border-color: #ccc')
        template(#default='{ expanded }')
          phone-icon(v-if='expanded', style='color: white')
          phone-call-icon(v-else, style='color: white')
      b-collapse#contact-bar.justify-content-center(is-nav)
        b-navbar-nav
          b-nav-item.mx-3(v-for='contact in contactData', :key='contact.id')
            b-img.mr-2(:src='require(`@/assets/images/icons/${contact.icon}`)', width='15')
            span.text-sm {{ contact.text }}
  b-navbar.bb-1(variant='white', type='light', toggleable='lg')
    b-container
      b-navbar-brand(:to='{ name: "Home" }')
        b-img(src='@/assets/images/logo.png', height='30')
      b-navbar-toggle(target='navbar')
      b-collapse#navbar.justify-content-end(is-nav)
        b-navbar-nav.d-flex.justify-content-between.w-100.px-5
          b-nav-item-dropdown(no-caret)
            template(#button-content)
              span Productos
              chevron-down-icon.ml-1.icon-md
            b-dropdown-item(:to='{ name: "Brands" }') Marcas
            b-dropdown-item(:to='{ name: "Catalog" }') Productos
          b-nav-item(:to='{ name: "Novelties" }') Novedades
          b-nav-item(:to='{ name: "Contact" }') Contacto
          b-nav-form.d-lg-none
            b-button(variant='primary', :to='{ name: "Catalog" }') Cotizador {{ cart.length ? ` (${cart.length})` : "" }}
          b-nav-form.d-none.d-lg-inline-block
            b-dropdown(no-caret, ref='cart_dropdown', variant='primary', right, :text='"Cotizador" + (cart.length ? ` (${cart.length})` : "")', menu-class='cart-dropdown')
              b-dropdown-text
                plus-circle-icon.icon-sm.mb-1
                span.ml-2 Productos agregados
              b-dropdown-text
              b-dropdown-text.text-secondary.text-sm(v-if='!cart.length') No has agregado productos al cotizador
              b-dropdown-text(v-for='(product, index) in cart', :key='product.index')
                .d-flex.align-items-center.justify-content-between
                  span.text-truncate {{ product.name }}
                  trash-icon.icon-sm.text-danger.icon_img(@click='removeFromCart(product)')
              b-dropdown-text
              b-dropdown-form
                b-button(variant='primary', block, size='sm', :to='{ name: "Home", hash: "#mail" }') Enviar cotización
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { EventBus } from '@/helpers/bus'

export default {
  data() {
    return {
      contactData: [
        // {
        //   id: 1,
        //   icon: 'mail.png',
        //   text: 'hola@matelco.com',
        // },
        {
          id: 2,
          icon: 'phone.png',
          text: '55 56 70 38 77',
        },
        {
          id: 3,
          icon: 'phone.png',
          text: '24 64 62 64 25',
        },
        {
          id: 4,
          icon: 'phone.png',
          text: '26 64 61 81 12',
        },
      ],
    }
  },
  computed: {
    ...mapState({
      cart: (state) => state.cart,
    }),
  },
  methods: {
    ...mapActions(['removeFromCart']),
  },
  created() {
    EventBus.$on('showCart', () => {
      if (this.cart.length === 1) {
        this.$refs['cart_dropdown'].show()
      }
    })
  },
}
</script>

<style lang="scss">
.icon_img {
  width: 25px !important;
}

.navbar-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
}

.navbar-light .navbar-nav .nav-link {
  color: black !important;
}

.cart-dropdown {
  width: 300px;
}
</style>