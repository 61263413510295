<template lang="pug">
.section
  b-container
    b-row
      b-col(md='6', offset-md='3')
        h3.text-center.font-weight-bold.mb-3 Solicitar cotización
        p.text-center.text-secondary Envíanos un mensaje con los productos que deseas cotizar y con gusto te contestaremos.
        validation-observer(v-slot='{handleSubmit, errors}')
          b-form(@submit.prevent='handleSubmit(send)')
            b-row
              b-col.mb-3(md='6')
                b-form-group(label='Nombre *')
                  validation-provider(rules='required|alpha_spaces', name='nombre', v-slot='{valid, dirty}')
                    b-form-input.bg-grey(v-model='name', :class='{ invalid: dirty && !valid }')
              b-col.mb-3(md='6')
                b-form-group(label='Apellido')
                  validation-provider(rules='alpha_spaces', name='apellido', v-slot='{valid, dirty}')
                    b-form-input.bg-grey(v-model='last_name', :class='{ invalid: dirty && !valid }')
              b-col.mb-3(md='6')
                b-form-group(label='Correo electrónico *')
                  validation-provider(rules='required|email', name='correo electrónico', v-slot='{valid, dirty}')
                    b-form-input.bg-grey(v-model='mail', :class='{ invalid: dirty && !valid }')
              b-col.mb-3(md='6')
                b-form-group(label='Teléfono de contacto *')
                  validation-provider(rules='required|numeric|length:10', name='teléfono', v-slot='{valid, dirty}')
                    b-form-input.bg-grey(v-model='phone', :class='{ invalid: dirty && !valid }')

              b-col.mb-2(cols='12')
                b-form-group(label='Productos a cotizar *', v-if='products !== null')
                  b-form-checkbox(v-for='(product, index) in cart', :key='product.index', v-model='products[index].state') {{ product.name }}

              b-col.mb-5(cols='12')
                b-form-group(label='Comentarios')
                  b-form-textarea.bg-grey(rows='3', max-rows='5', no-resize, v-model='comments')

              b-col.mb-5(cols='12')
                h5.text-center.font-weight-bold.mb-3 Selecciona una sucursal para enviar la solicitud
                b-form-group.align-center
                  b-form-radio-group(v-model='selectedBranch', :options='branches', buttons, button-variant='outline-primary')

              b-col.mb-0(cols='12')
                b-form-group.text-center
                  p.text-danger(v-if='parseErrors(errors)', v-for='error in parseErrors(errors)', :key='error') {{ error }}

              b-col.mb-5(cols='12')
                vue-recaptcha.captcha(sitekey='6Lej_U8aAAAAAMDjJPCUOrCN55wdKlATL5YJ_Fl3', ref='recaptcha', @verify='onVerify', @expired='onExpired')
              b-col.text-center(cols='12')
                b-button.px-5(type='submit', variant='primary') Enviar
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  data() {
    return {
      name: null,
      last_name: null,
      mail: null,
      phone: null,
      comments: null,
      products: null,
      selectedBranch: 'cdmx',
      branches: [
        {
          text: 'Oficinas CDMX',
          value: 'cdmx',
        },
        {
          text: 'Tienda Tlaxcala',
          value: 'tlax',
        },
        {
          text: 'Centro Distribución Xiloxotla',
          value: 'xilo',
        },
      ],
    }
  },
  computed: {
    ...mapState({
      cart: (state) => state.cart,
    }),
  },
  methods: {
    ...mapActions(['sendMail']),
    parseErrors(errors) {
      return Object.values(errors)
        .map((element) => element[0])
        .filter((error) => error)
    },
    async send() {
      let response = await this.sendMail({ name: this.name, last_name: this.last_name, mail: this.mail, phone: this.phone, comments: this.comments, products: this.products, branch: this.selectedBranch })

      if (response) {
        this.$alert.$emit('alert', {
          type: 'success',
          message: 'El correo se envió con exito, nos contactaremos contigo lo mas pronto posible',
        })
      } else {
        this.$alert.$emit('alert', {
          type: 'danger',
          message: 'Ocurrio un error al enviar un mensaje, intentalo nuevamente',
        })
      }
    },
    onVerify: function (response) {
      this.verified = true
    },
    onExpired: function () {
      this.verified = false
      this.clearForm()
    },
  },
  mounted() {
    if (this.cart.length !== 0) this.products = this.cart.map((value) => ({ name: value.name, tlx: value.tlx, state: true }))

    if (process.env.NODE_ENV != 'production') {
      this.name = 'test'
      this.last_name = 'test'
      this.mail = 'test@test.com'
      this.phone = '0123456789'
      this.comments = 'test'
    }
  },
}
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.captcha{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.align-center{
  display: flex;
  align-items: center;
  justify-content: center;
}

.invalid {
  border: 2px solid red !important;
}

.form-control.bg-grey {
  background: #f3f3f3;
  border-color: #f3f3f3;
}

.section {
  padding-top: 1rem;
  padding-bottom: 1rem;

  @include media-breakpoint-up(lg) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}
</style>